import styled from "styled-components";
import "../App.scss";
import { useState, useEffect } from "react";
import { BigNumber, ethers } from "ethers";
import TransactionStatus from "./TransactionStatus";
import Warning from "./Warning";
import {
  HYDRA_CONTRACT_ADDRESS,
  SUPER_STAKER_ADDRESS,
  hydraToHexAddress,
  FEE,
  UTXO_THRESHOLD,
} from "../helpers/constants";
import axios from "axios";
import hydraContractABI from "../contracts/hydraContractABI.json";
import { Hydraweb3 } from "ipetrov22-hydraweb3-js";

//axios setting
axios.defaults.baseURL = `https://${process.env.REACT_APP_CHANGEX_BASE_URL}`;

//=========================================================
//Styled Components
//=========================================================

type Account = {
  loggedIn: boolean;
  name: string;
  network: string;
  address: string;
  balance: number;
};

interface Props {
  darkmode: boolean;
  hydraWeb3?: any;
  loggedIn?: boolean;
  connectWallet?: any;
  account?: Account;
}
const AddAndRemoveDelegation = styled.button<Props>`
  width: 18em;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  padding: 0.1em;
  background-color: ${(props) => (props.darkmode ? "#1d2e48" : "#e1f2ff")};
  border-radius: 20px;
  border: none;
  color: #026fc2;
  margin: 1em;
  font-weight: 300;
  &:hover {
    background-color: #d7edfe;
    cursor: pointer;
  }
  &:disabled {
    background-color: ${(props) => (props.darkmode ? `#40444f` : `#e8e8e8`)};
    opacity: 0.5;
  }
  &:disabled:hover {
    cursor: default;
  }
  @media (max-width: 1000px) {
    width: 10em;
    border-radius: 12px;
    font-size: 1.4em;
    height: 4em;
    margin: 0.4em;
    padding: 0.3em;
  }
`;
const OptimizeUtxosButton = styled.button<Props>`
  width: 10em;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  padding: 0.1em;
  background-color: ${(props) => (props.darkmode ? "#1d2e48" : "#e1f2ff")};
  border-radius: 20px;
  border: none;
  color: #026fc2;
  font-weight: 300;
  &:hover {
    background-color: #d7edfe;
    cursor: pointer;
  }
  &:disabled {
    background-color: ${(props) => (props.darkmode ? `#40444f` : `#e8e8e8`)};
    opacity: 0.5;
  }
  &:disabled:hover {
    cursor: default;
  }
  @media (max-width: 1000px) {
    width: 10em;
    border-radius: 12px;
    font-size: 1.4em;
    height: 4em;
    margin: 0.4em;
    padding: 0.3em;
  }
`;
const ButtonCluster = styled.div`
  width: 25em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    width: 23em;
    height: 128vh;
    justify-content: space-evenly;
    font-size: 0.6em;
  }
`;
const ConnectButton = styled.div<Props>`
  width: 30em;
  font-size: 17px;
  display: flex;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 3px;
  background-color: ${(props) => (props.darkmode ? "#1d2e48" : "#e1f2ff")};
  border-radius: 20px;
  border: none;
  color: ${(props) => (props.darkmode ? `#6da8ff` : `#026fc2`)};
  font-weight: 200;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    width: 17em;
    border-radius: 12px;
  }
`;

const Body = styled.div<Props>`
  font-size: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: ${(props) =>
    props.darkmode
      ? "radial-gradient(50% 50% at 50% 50%, rgba(2, 111, 194, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
      : "radial-gradient(50% 50% at 50% 50%, rgba(33, 114, 229, 0.1) 0%, rgba(33, 36, 41, 0) 100%)"};
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${(props) =>
    props.darkmode ? "rgb(44, 47, 54)" : "rgb(247, 248, 250)"};
`;

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwapBox = styled.div<Props>`
  z-index: 1;
  position: absolute;
  background-color: ${(props) => (props.darkmode ? "#212429" : "white")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 26em;
  border-radius: 30px;
  font-weight: 200;
  font-size: 0.7em;
  border: 2px;
  position: relative;
  box-shadow: 0px 0px 50px rgba(2, 111, 194, 0.5);
  padding: 16px;
  margin-top: 70px;
  color: ${(props) => (props.darkmode ? "white" : "black")};
  @media (max-width: 1000px) {
    width: 15em;
    height: 7em;
    padding: 0.5em;
  }
`;

const Apy = styled.div<Props>`
  font-size: 0.6em;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 1em;
  width: 25em;
  z-index: -0.5;
  background-color: ${(props) => (props.darkmode ? "#212429" : "white")};
  @media (max-width: 1000px) {
    height: 7em;
    font-size: 0.45em;
    margin-top: 17.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 23em;
    opacity: 0.8;
  }
`;

const AnnualReward = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.darkmode ? "white" : "black")};
`;

const ApyPercent = styled.div<Props>`
  display: flex;
  color: ${(props) => (props.darkmode ? "white" : "black")};

  justify-content: space-between;
`;

//===============================================
//Component Body
//===============================================

export default function Main({
  darkmode,
  hydraWeb3,
  loggedIn,
  connectWallet,
  account,
}: Props) {
  //=========================================================
  //State Management
  //=========================================================
  const [showTxSpinner, setShowTxSpinner] = useState<boolean>(false);
  const [txStatusMessage, setTxStatusMessage] = useState<string>("");
  const [warning, setWarning] = useState<string>("");
  const [stakingInfo, setStakingInfo] = useState<{
    data: {
      apy: number;
      earned: number;
      fee: number;
      isStaking: boolean;
      staked: number;
    };
  }>({
    data: { apy: 0, earned: 0, fee: 0, isStaking: false, staked: 0 },
  });
  const [hydraContract, setHydraContract] = useState<any | null>();
  const [sumOfUTXOSForOptimization, setSumOfUTXOSForOptimization] =
    useState<BigNumber>(BigNumber.from(0));
  const [numberofUTXOSforOptimization, setNumberofUTXOSforOptimization] =
    useState<number>(0);
  const [
    numberofUTXOSAboveMinAmountForDelegation,
    setNumberofUTXOSAboveMinAmountForDelegation,
  ] = useState<number>(0);

  useEffect(() => {
    if (account !== undefined && loggedIn) {
      if (stakingInfo?.data.isStaking) {
        setWarning("");
      }
      if (BigNumber.from(account.balance).lt(BigNumber.from(100).mul(1e8))) {
        setWarning(
          `Delegation not possible. Please make sure that your balance is above 100 HYDRA.`
        );
      }
      if (
        numberofUTXOSforOptimization > 1 &&
        BigNumber.from(account.balance).gt(BigNumber.from(100).mul(1e8))
      ) {
        setWarning(`
        ${ethers.utils.formatUnits(sumOfUTXOSForOptimization, 8)}
        HYDRA available
        for optimization!`);
      }
    } else {
      setWarning("");
    }
  }, [
    account,
    numberofUTXOSforOptimization,
    numberofUTXOSAboveMinAmountForDelegation,
    sumOfUTXOSForOptimization,
    stakingInfo,
    loggedIn,
  ]);

  useEffect(() => {
    if (account !== undefined && loggedIn) {
      setHydraContract(
        hydraWeb3.Contract(HYDRA_CONTRACT_ADDRESS, hydraContractABI)
      );
      checkForUTXOOptimization();
      checkForUTXOsAboveMinAmountForDelegation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, account?.balance, loggedIn, hydraWeb3, stakingInfo]);

  useEffect(() => {
    if (txStatusMessage === "" || !loggedIn) {
      showSpinner("");
    }
  }, [loggedIn]);

  useEffect(() => {
    if (account !== undefined && loggedIn && account.network === "MainNet") {
      axios
        .get(`/data?address=${account.address}`)
        .then((response) => {
          setStakingInfo(response.data);
        })
        .catch((e) => {
          console.error(e.message);
        });
    }
  }, [account?.network, account?.balance, loggedIn]);

  function showSpinner(message: string) {
    setShowTxSpinner(true);
    setTxStatusMessage(message);
  }

  //===============================================
  //Hydra Staking contract invocations
  //===============================================

  const addDelegation = async () => {
    showSpinner("Transaction in progress. Generating transaction id ...");

    try {
      let hexStakerAddress = hydraToHexAddress(SUPER_STAKER_ADDRESS);
      let message = hexStakerAddress;
      message = await hydraWeb3.provider.signMessage(message);

      //the staker address in methodArgs arr must be passed in hex representation
      const stake = await hydraContract?.send("addDelegation", {
        methodArgs: [
          hexStakerAddress,
          FEE,
          ethers.utils.base64.decode(message),
        ],
        gasLimit: 2250000,
        senderAddress: account?.address,
      });

      showSpinner(stake.id);

      return stake;
    } catch (e) {
      if (e instanceof Error) {
        showSpinner(e.message);
      }
    }
  };

  const removeDelegation = async () => {
    showSpinner("Transaction in progress. Generating transaction id ...");

    try {
      const unstake = await hydraContract?.send("removeDelegation", {
        methodArgs: [],
        gasLimit: 2250000,
        senderAddress: account?.address,
      });
      showSpinner(unstake.id);
      return unstake;
    } catch (e) {
      if (e instanceof Error) {
        showSpinner(e.message);
      }
    }
  };

  const optimizeUTXOS = async () => {
    showSpinner("Transaction in progress. Generating transaction id ...");
    try {
      const tx = await hydraWeb3.provider.optimizeUTXOS();
      tx.id ? showSpinner(tx.id) : showSpinner(tx);
      console.log(tx);
      return tx;
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
        showSpinner(e.message);
      }
    }
  };

  //=========================================================
  // API calls for additional account information
  //=========================================================

  function sumUTXOS(utxos: any): BigNumber {
    let sum = BigNumber.from(0);
    for (let utxo of utxos) {
      let value = BigNumber.from(utxo.value);
      sum = sum.add(value);
    }
    return sum;
  }

  async function checkForUTXOsAboveMinAmountForDelegation() {
    let explorerPrefix = account?.network === "MainNet" ? "" : "test";
    axios
      .get(
        `https://${explorerPrefix}explorer.hydrachain.org/api/address/${account?.address}/utxo`
      )
      .then((response) => {
        let UTXOSAboveMinValueForDelegation = response.data.filter(
          (utxo: any) => {
            let utxoIsStaked: boolean = utxo.isStake;
            let value = BigNumber.from(utxo.value);
            let confirmations: BigNumber = BigNumber.from(utxo.confirmations);

            if (
              (value.gte(BigNumber.from(100).mul(1e8)) &&
                utxoIsStaked === false) ||
              (value.gte(BigNumber.from(100).mul(1e8)) &&
                confirmations.gt(BigNumber.from(2000)) &&
                utxoIsStaked)
            ) {
              return true;
            }
            return false;
          }
        );
        setNumberofUTXOSAboveMinAmountForDelegation(
          UTXOSAboveMinValueForDelegation.length
        );
      })
      .catch((e) => {
        console.error(e.message);
      });
  }

  async function checkForUTXOOptimization() {
    let explorerPrefix = account?.network === "MainNet" ? "" : "test";
    axios
      .get(
        `https://${explorerPrefix}explorer.hydrachain.org/api/address/${account?.address}/utxo`
      )
      .then((response) => {
        let arrayOfValidUTXOS = response.data.filter((utxo: any) => {
          let value: BigNumber = BigNumber.from(utxo.value);
          let confirmations: BigNumber = BigNumber.from(utxo.confirmations);
          let utxoIsStaked: boolean = utxo.isStake;
          if (
            value.gt(BigNumber.from(25).mul(1e6)) &&
            value.lt(BigNumber.from(UTXO_THRESHOLD).mul(1e8)) &&
            (utxoIsStaked === false ||
              (confirmations.gt(BigNumber.from(2000)) && utxoIsStaked))
          ) {
            return true;
          }
          return false;
        });
        setNumberofUTXOSforOptimization(arrayOfValidUTXOS.length);
        setSumOfUTXOSForOptimization(sumUTXOS(arrayOfValidUTXOS));

        if (
          arrayOfValidUTXOS.length > 0 &&
          BigNumber.from(account?.balance).gte(BigNumber.from(100).mul(1e8))
        ) {
          setShowTxSpinner(true);
        }
      })
      .catch((e) => {
        console.error(e.message);
      });
  }

  return (
    <Body darkmode={darkmode}>
      <div id="staking-div" style={{ color: darkmode ? "white" : "black" }}>
        <span id="hydra-ticker">$HYDRA</span>Staking
      </div>
      <Wrapper className="wrapper">
        <SwapBox darkmode={darkmode}>
          <span style={{ fontSize: "22px" }}>Delegated Staking</span>
          {loggedIn ? (
            <>
              <ButtonCluster>
                <AddAndRemoveDelegation
                  darkmode={darkmode}
                  onClick={addDelegation}
                  disabled={
                    BigNumber.from(account?.balance).lt(
                      BigNumber.from(100).mul(1e8)
                    ) || stakingInfo?.data.isStaking === true
                      ? true
                      : false
                  }
                >
                  Add Delegation
                </AddAndRemoveDelegation>

                <AddAndRemoveDelegation
                  darkmode={darkmode}
                  onClick={removeDelegation}
                  disabled={stakingInfo?.data.isStaking ? false : true}
                >
                  {" "}
                  Remove Delegation{" "}
                </AddAndRemoveDelegation>
              </ButtonCluster>
              <OptimizeUtxosButton
                darkmode={darkmode}
                onClick={optimizeUTXOS}
                disabled={
                  numberofUTXOSforOptimization > 1 &&
                  BigNumber.from(account?.balance).gt(
                    BigNumber.from(100).mul(1e8)
                  )
                    ? false
                    : true
                }
              >
                {" "}
                Optimize UTXOS{" "}
              </OptimizeUtxosButton>
            </>
          ) : (
            <ConnectButton
              darkmode={darkmode}
              onClick={() => {
                connectWallet();
              }}
            >
              {" "}
              Connect Wallet{" "}
            </ConnectButton>
          )}
        </SwapBox>
      </Wrapper>
      {loggedIn ? (
        <Apy className="Apy" darkmode={darkmode}>
          <ApyPercent darkmode={darkmode}>
            <span>Staked:</span>
            <span>
              {" "}
              {Number(
                ethers.utils.formatUnits(String(stakingInfo.data.staked), 8)
              )}{" "}
            </span>
          </ApyPercent>
          <ApyPercent darkmode={darkmode}>
            <span>Earned:</span>
            <span>
              {" "}
              {Number(
                ethers.utils.formatUnits(String(stakingInfo.data.earned), 8)
              )}{" "}
            </span>
          </ApyPercent>
          <AnnualReward darkmode={darkmode}>
            <span>Pool fee:</span>
            <span>{stakingInfo?.data.fee}</span>
          </AnnualReward>
          <ApyPercent darkmode={darkmode}>
            <span>APY:</span>
            <span> {stakingInfo?.data.apy} </span>
          </ApyPercent>
        </Apy>
      ) : (
        ""
      )}
      {showTxSpinner && txStatusMessage !== "" && (
        <TransactionStatus
          darkmode={darkmode}
          message={txStatusMessage}
          account={account}
          setShowTxSpinner={setShowTxSpinner}
        ></TransactionStatus>
      )}
      {warning !== "" && (
        <Warning
          darkmode={darkmode}
          warning={warning}
          setWarning={setWarning}
          sumOfUTXOSForOptimization={sumOfUTXOSForOptimization}
        ></Warning>
      )}
    </Body>
  );
}
