import { ethers } from "ethers";

export const DECIMALS: number = 8;
export const HYDRA_CONTRACT_ADDRESS: string =
  "0000000000000000000000000000000000000093";
export const SUPER_STAKER_ADDRESS: string =
  "HA1nWXBKV4r5B6p8WZLWEmCjHo7TQqBTZw";
export const FEE: number = 100;
export const UTXO_THRESHOLD = 150;

//=====================helper-functions================
export const hydraToHexAddress = (address: string) => {
  const hex = ethers.utils
    .hexlify(ethers.utils.base58.decode(address))
    .substring(4, 44);
  return hex;
};
