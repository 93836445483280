import styled from "styled-components";
import Image from "./Image";
import { ethers, BigNumber } from "ethers";
import "../App.scss";
import MediaQuery from "react-responsive";

//=========================================================
//Styled Components
//=========================================================

type Account = {
  loggedIn: boolean;
  name: string;
  network: string;
  address: string;
  balance: number;
};

interface Props {
  darkmode: boolean;
  dark?(): any;
  account?: Account;
  loggedIn?: boolean;
  hydraWeb3?: any;
  connectWallet?(): any;
}

const ConnectButton = styled.button<Props>`
  width: 12em;
  font-size: 15px;
  height: 2.5em;
  padding: 0.1em;
  background-color: ${(props) => (props.darkmode ? "#1d2e48" : "#e1f2ff")};
  border-radius: 12px;
  border: none;
  color: ${(props) => (props.darkmode ? `#6da8ff` : `#026fc2`)};
  font-weight: 500;
  margin-right: 15px;
  &:hover {
    border: 1px solid #026fc2;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    margin-right: 0.1em;
    margin-left: 0.1em;
  }
`;

const ConnectedButton = styled.button<Props>`
  display: flex;
  align-items: center;
  width: 16em;
  font-size: 15px;
  height: 2.5em;
  padding: 0.1em;
  background-color: ${(props) => (props.darkmode ? "#1d2e48" : "#e1f2ff")};
  border-radius: 12px;
  border: none;
  color: #6da8ff;
  font-weight: 500;
  margin-right: 1em;
  padding-left: 1.5em;

  @media (max-width: 1000px) {
    margin-left: 5px;
  }
`;
const HeaderFrame = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: auto;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  font-weight: 500;
  background-image: ${(props) =>
    props.darkmode
      ? "radial-gradient(50% 50% at 50% 50%, rgba(33, 114, 229, 0.1) 0%, rgba(33, 36, 41, 0) 100%)"
      : "radial-gradient(50% 50% at 50% 50%, rgba(2, 111, 194, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"};

  background-color: ${(props) =>
    props.darkmode ? "rgb(44, 47, 54)" : "rgb(247, 248, 250)"};
`;

const BalanceDiv = styled.div<Props>`
  margin-left: auto;
  width: 5em;
  height: 2.1em;
  background-color: ${(props) => (props.darkmode ? `#40444f` : `#e8e8e8`)};
  border-radius: 12px;
  border: none;
  color: #6da8ff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
`;

const ThemeToggle = styled.button<Props>`
  height: 2.7em;
  width: 3em;
  border-radius: 10px;
  margin: 0px !important;
  border: none;
  background-color: ${(props) => (props.darkmode ? `#40444f` : `#e8e8e8`)};
  &:hover {
    cursor: pointer;
  }
`;

//=========================================================
//Component Body
//=========================================================
export default function Header({
  dark,
  darkmode,
  account,
  loggedIn,
  connectWallet,
}: Props) {
  return (
    <>
      <MediaQuery maxWidth={1000}>
        <div id="extension-version-or-mobile-device-banner">
          Staking functionality is not available on mobile devices. Please use
          the HydraWallet Chrome extension on a desktop.
        </div>
      </MediaQuery>
      {window.hydrawallet &&
        typeof window.hydrawallet.rpcProvider.signMessage !== "function" && (
          <div id="extension-version-or-mobile-device-banner">
            You are not using the latest version of the HydraWallet Chrome
            extension. Please update it{" "}
            <a
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "550",
              }}
              href="https://chrome.google.com/webstore/detail/hydrawallet/polcbnennmbhbdoafiicjgccofalcncl"
              target="blank"
            >
              {" "}
              here.{" "}
            </a>
          </div>
        )}
      {account?.address === "" && (
        <div id="connect-extension-banner">
          Please log in to your Hydra account via the HydraWallet extension.
        </div>
      )}
      {typeof window.hydrawallet === "undefined" && (
        <MediaQuery minWidth={1000}>
          <div id="extension-version-or-mobile-device-banner">
            HydraWallet Extension not detected. Click on "Connect to a wallet"
            or download it from{" "}
            <a
              style={{
                color: "darkblue",
                marginLeft: "0.2em",
                fontWeight: "400",
              }}
              href="https://chrome.google.com/webstore/detail/hydrawallet/polcbnennmbhbdoafiicjgccofalcncl"
              target="blank"
            >
              {" "}
              here.{" "}
            </a>
          </div>
        </MediaQuery>
      )}

      <HeaderFrame darkmode={darkmode}>
        {darkmode ? (
          <a href="https://changex.io/" target="blank" rel="noreferrer">
            <Image
              darkmode={darkmode.toString()}
              src="/changex-light.png"
              style={{
                width: 40,
                backgroundColor: darkmode
                  ? "rgb(44, 47, 54)"
                  : "rgb(247, 248, 250)",
                borderRadius: 50,
                borderStyle: "none",
              }}
            />
          </a>
        ) : (
          <a href="https://changex.io/" target="blank" rel="noreferrer">
            <Image
              darkmode={darkmode.toString()}
              src="/changex-dark.png"
              style={{
                width: 40,
                backgroundColor: darkmode
                  ? "rgb(44, 47, 54)"
                  : "rgb(247, 248, 250)",
                borderRadius: 50,
                borderStyle: "none",
              }}
            />
          </a>
        )}

        <div style={{ display: "flex" }}>
          {loggedIn === true ? (
            <ConnectedButton darkmode={darkmode} onClick={() => {}}>
              {Number(
                ethers.utils.formatUnits(BigNumber.from(account?.balance), 8)
              ).toFixed(3)}{" "}
              HYDRA
              <BalanceDiv darkmode={darkmode} className="balance-div">
                {" "}
                {`
                ${String(account?.address.slice(0, -30))}...${String(
                  account?.address.slice(-2)
                )}
              `}
              </BalanceDiv>
            </ConnectedButton>
          ) : (
            <ConnectButton darkmode={darkmode} onClick={connectWallet}>
              Connect to a wallet
            </ConnectButton>
          )}

          <ThemeToggle darkmode={darkmode} onClick={dark}>
            {darkmode ? (
              <Image src="/moon.png" style={{ width: 22 }} />
            ) : (
              <Image src="/sun.svg" style={{ width: 22, paddingTop: 4 }} />
            )}
          </ThemeToggle>
        </div>
      </HeaderFrame>
    </>
  );
}
