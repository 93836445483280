import "../App.scss";
import styled from "styled-components";
import { BigNumber, ethers } from "ethers";
import React from "react";

//=========================================================
//Styled Components
//=========================================================
interface Props {
  darkmode: boolean;
  warning: string;
  setWarning: any;
  sumOfUTXOSForOptimization: BigNumber;
}

const WarningPopup = styled.div<Props>`
  margin-top: 2.5em;
  font-size: 0.6em;
  display: flex;
  padding: 0.5em;
  border-radius: 15px;
  color: ${(props) => (props.darkmode ? "white" : "black")};
  font-size: 0.5em;
  align-items: center;
  background-color: ${(props) => (props.darkmode ? "#212429" : "white")};
  @media (max-width: 1000px) {
    font-size: 0.45em;
    margin-top: 29.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25em;
    opacity: 0.8;
  }
`;
//=========================================================
//Component Body
//=========================================================
export default function Warning({
  darkmode,
  warning,
  setWarning,
  sumOfUTXOSForOptimization,
}: Props) {
  return (
    <WarningPopup
      sumOfUTXOSForOptimization={sumOfUTXOSForOptimization}
      darkmode={darkmode}
      warning={warning}
      setWarning={setWarning}
    >
      <span className="orange"></span>
      <div className="warning-message-box"> {warning}</div>
      <button
        id="close-notification-button"
        style={{
          backgroundColor: darkmode ? `#40444f` : `#e8e8e8`,
          color: darkmode ? "#e1f2ff" : "#1d2e48",
        }}
        onClick={() => setWarning("")}
      >
        X
      </button>
    </WarningPopup>
  );
}
