import "./App.scss";
import Header from "./components/Header";
import Main from "./components/Main";
import { useEffect, useState } from "react";
import { Hydraweb3 } from "ipetrov22-hydraweb3-js";
import invoke from "./mobileInvoke/browser";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  const rawCall = invoke.bind("rawCall");
  const [error, setError] = useState("");
  const [walletExtension, setWalletExtension] = useState<Account>();
  const [hydraWeb3, setHydraWeb3] = useState({});
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  type Account = {
    loggedIn: boolean;
    name: string;
    network: string;
    address: string;
    balance: number;
  };
  //=========================================================
  //State Management
  //=========================================================

  useEffect(() => {
    if (window.hydrawallet) {
      return;
    }
    window.addEventListener(
      "message",
      function handleHydrawalletInstalledOrUpdated(event) {
        if (
          event.data.message &&
          event.data.message.type === "HYDRAWALLET_INSTALLED_OR_UPDATED"
        ) {
          // Refresh the page
          window.location.reload();
        }
      },
      false
    );

    window.addEventListener(
      "message",
      function handleHydrawalletAcctChanged(event) {
        if (
          event.data.message &&
          event.data.message.type === "HYDRAWALLET_ACCOUNT_CHANGED"
        ) {
          if (event.data.message.payload.error) {
            // handle error
            setError(event.data.message.payload.error);
            console.error(error);
          }
          setError("");
          // should be in state where wallet or contract functions are used
          const account: Account = event.data.message.payload.account;
          const hydraExtension = new Hydraweb3(
            window.ReactNativeWebView
              ? { rawCall }
              : window.hydrawallet.rpcProvider
          );

          setWalletExtension(account);
          setLoggedIn(account.loggedIn);
          setHydraWeb3(hydraExtension);
        }
      },
      false
    );

    // Handle incoming messages
    window.addEventListener(
      "message",
      function handleMessage(message) {
        if (message.data.target === "hydrawallet-inpage") {
          // result: object
          // error: string
          const { result, error } = message.data.message.payload;
          if (error) {
            if (
              error === "Not logged in. Please log in to hydrawallet first."
            ) {
              // Show an alert dialog that the user needs to login first
              alert(error);
            } else {
              // Handle different error than not logged in...
              console.log(result);
            }
            return;
          }

          if (
            message?.data?.payload?.statusChangeReason === "Account Logged Out"
          ) {
            window.postMessage(
              { message: { type: "CONNECT_HYDRAWALLET" } },
              ""
            );
          }
          // Do something with the message result...
        }
      },
      false
    );
  });

  const [darkmode, setdarkmode] = useState<boolean>(
    localStorage.getItem("dark-mode") === "true"
  );
  useEffect(() => {
    localStorage.setItem("dark-mode", String(darkmode));
  }, [darkmode]);

  const dark = () => {
    setdarkmode(!darkmode);
  };
  const connectWallet = (): void => {
    window.postMessage({ message: { type: "CONNECT_HYDRAWALLET" } }, "*");
  };

  return (
    <div className="App">
      <Header
        darkmode={darkmode}
        dark={dark}
        hydraWeb3={hydraWeb3}
        account={walletExtension}
        loggedIn={loggedIn}
        connectWallet={connectWallet}
      />
      <Main
        darkmode={darkmode}
        hydraWeb3={hydraWeb3}
        loggedIn={loggedIn}
        connectWallet={connectWallet}
        account={walletExtension}
      />
    </div>
  );
}

export default App;
