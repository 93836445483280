import "../App.scss";
import styled from "styled-components";
import { BigNumber, ethers } from "ethers";

//=========================================================
//Styled Components
//=========================================================
interface Props {
  darkmode: boolean;
  message: string;
  account: any;
  setShowTxSpinner?: any;
}

const NotificationPopup = styled.div<Props>`
  margin-top: 5em;
  font-size: 0.6em;
  display: flex;
  padding: 0.5em;
  border-radius: 15px;
  width: 37em;
  color: ${(props) => (props.darkmode ? "white" : "black")};
  font-size: 0.5em;
  align-items: center;
  background-color: ${(props) => (props.darkmode ? "#212429" : "white")};
  @media (max-width: 1000px) {
    font-size: 0.45em;
    margin-top: 29.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25em;
    opacity: 0.8;
  }
`;
//=========================================================
//Component Body
//=========================================================
export default function TransactionStatus({
  darkmode,
  message,
  account,
  setShowTxSpinner,
}: Props) {
  return (
    <NotificationPopup
      darkmode={darkmode}
      message={message}
      account={account}
      setShowTxSpinner={setShowTxSpinner}
    >
      <span
        className={
          message === "Transaction in progress. Generating transaction id ..."
            ? "loading-spinner"
            : message.length === 64
            ? "loading-loading-spinner--successful"
            : "loading-spinner--error"
        }
      ></span>
      <div className="notification-message-box">
        {account.network === "MainNet" && message?.length === 64 ? (
          <a
            href={`https://explorer.hydrachain.org/tx/${message}`}
            target="_blank"
            style={{
              color: darkmode ? "white" : "black",
              textDecoration: "none",
            }}
          >
            Success! Click{" "}
            <span style={{ color: "blue", textDecoration: "underline" }}>
              here
            </span>{" "}
            to see the transaction on the block explorer.
          </a>
        ) : account.network === "TestNet" && message?.length === 64 ? (
          <a
            href={`https://testexplorer.hydrachain.org/tx/${message}`}
            target="_blank"
            style={{
              color: darkmode ? "white" : "black",
              textDecoration: "none",
            }}
          >
            Success! Click{" "}
            <span style={{ color: "blue", textDecoration: "underline" }}>
              here
            </span>{" "}
            to see the transaction on the block explorer.
          </a>
        ) : (
          JSON.stringify(message)
        )}
      </div>
      <button
        id="close-notification-button"
        style={{
          backgroundColor: darkmode ? `#40444f` : `#e8e8e8`,
          color: darkmode ? "#e1f2ff" : "#1d2e48",
        }}
        onClick={() => setShowTxSpinner(false)}
      >
        X
      </button>
    </NotificationPopup>
  );
}
